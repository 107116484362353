import DataTable from "examples/Tables/DataTable";
import PropTypes from "prop-types";

function Tables({ columns, rows }) {
  return (
    <DataTable
      table={{ columns, rows }}
      isSorted={false}
      entriesPerPage={false}
      showTotalEntries={false}
      noEndBorder
    />
  );
}

Tables.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default Tables;
