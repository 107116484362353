import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Modal,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { DirectionsRenderer, InfoWindow, Marker } from "@react-google-maps/api";
import CustomMap from "components/GoogleMap";
import { markerUrl } from "helper";
import React, { useEffect, useState } from "react";
import {
  scoClustering,
  scoDynamicClusterData,
  getScoDayWiseList,
  fetchCenterUserList,
  getScoList,
  getSeoBranchList,
} from "services";
import earth from "../../assets/icon/roatedEarth.gif";
import ReactModal from "react-modal";
import Table from "layouts/tables/index";
import { Close, Route } from "@mui/icons-material";
import { chunkArray } from "api/constants";
import { fetchNearestCenterList } from "services";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SCO = () => {
  const [branchId, setBranchId] = useState({ branch_id: 3, branch_name: "Chomu" });
  const [branchList, setBranchList] = useState([{ branch_id: 3, branch_name: "Chomu" }]);
  const [scoList, setScoList] = useState([]);
  const [scoId, setScoId] = useState(25598);
  const [oldCount, setOldCount] = useState(null);
  const [scoDayCount, setScoDaysCount] = useState(null);
  const [oldData, setOldData] = useState([]);
  const [branchLatLng, setBranchLetLng] = useState([]);
  const [centerDetails, setCenterDetails] = useState(null);
  const [dynamicCenterDetails, setDynamicCenterDetails] = useState(null);
  const [dayWisePin, setDayWisePin] = useState([]);
  const [dynamicDayWisePin, setDynamicDayWisePin] = useState([]);
  const [allDynamicData, setAllDynamicData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dayValue, setDayValue] = useState("all");
  const [userModal, setUserModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [dayWiseData, setDayWiseData] = useState([]);
  const [direction, setDirection] = useState([]);
  const [oldBranchDetails, setOldBranchDetails] = useState(null);
  const [branchDetails, setBranchDetails] = useState(null);
  const [openNearBy, setOpenNearBy] = useState(false);
  const [loadingNearBy, setLoadingNearBy] = useState(false);
  const [centerNearBy, setCenterNearBy] = useState(null);
  const [nearByCenterDetails, setNearByCenterDetails] = useState(null);
  const [nearestCenterDirection, setNearestCenterDirection] = useState(null);
  const [routeLoading, setRouteLoading] = useState(false);

  const userColumns = [
    {
      Header: "Center Id",
      accessor: "center_id",
      align: "left",
    },
    {
      Header: "Loan Id",
      accessor: "loan_id",
      align: "left",
    },
    {
      Header: "First Name",
      accessor: "first_name",
      align: "left",
    },
    {
      Header: "Middle Name",
      accessor: "middle_name",
      align: "left",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      align: "left",
    },
    {
      Header: "Mobile Number",
      accessor: "mobile_number",
      align: "left",
    },
  ];

  const transformData = (data) => {
    let transformedData = [];
    let pinPoint = [];
    const keys = Object.keys(data);
    keys?.map((item, index) => {
      const url = markerUrl[index];
      pinPoint.push({ day: item, pin: url });
      transformedData.push(
        ...data[item]?.map((center) => ({
          ...center,
          url: url,
          scaledSize: [30, 30],
        }))
      );
    });
    setDayWisePin(pinPoint);
    return transformedData;
  };

  const transformDynamicData = (data) => {
    let transformedData = [];
    let pinPoint = [];
    const keys = Object.keys(data);
    keys?.map((item, index) => {
      const url = markerUrl[index];
      pinPoint.push({ day: item, pin: url });
      transformedData.push(
        ...data[item]?.map((center) => ({
          ...center,
          url: url,
          scaledSize: [30, 30],
        }))
      );
    });
    setDynamicDayWisePin(pinPoint);
    return transformedData;
  };

  const makeClustering = async (branchId, userId) => {
    const res = await scoClustering(branchId, userId);
    if (res) {
      const response = await scoDynamicClusterData(branchId, userId);
      if (response) {
        setDayWiseData(response?.CENTER_PER_CLUSTER);
        setScoDaysCount(response?.CENTER_NO_PER_CLUSTER);
        setAllDynamicData(transformDynamicData(response?.CENTER_PER_CLUSTER));
      }
      setLoading(false);
    }
  };

  const fetchSeoBranch = async () => {
    const res = await getSeoBranchList();
    if (res) {
      setBranchList(res);
    }
  };

  const fetchSeoList = async (branchId) => {
    const res = await getScoList(branchId);
    if (res) {
      setScoList(res);
    }
  };

  const fetchDayWiseDataList = async (branchId, userId) => {
    setLoading(true);
    const res = await getScoDayWiseList(branchId, userId);
    if (res) {
      makeClustering(branchId, userId);
      setBranchLetLng(res?.BRANCH_LAT_LONG?.[0]);
      setOldCount(res?.CENTER_NO_PER_CLUSTER);
      setOldData(transformData(res?.CENTER_PER_CLUSTER));
    }
  };

  useEffect(() => {
    fetchSeoBranch();
    return () => {};
  }, []);

  useEffect(() => {
    if (branchId?.branch_id) {
      fetchSeoList(branchId?.branch_id);
    }
    return () => {};
  }, [branchId]);

  useEffect(() => {
    if (scoId && branchId?.branch_id) {
      fetchDayWiseDataList(branchId?.branch_id, scoId);
    }
  }, [branchId?.branch_id, scoId]);

  const handleBranchChange = (e, v) => {
    setScoId(null);
    setScoList([]);
    setBranchId(v);
    setDirection([]);
  };

  const handleScoChange = (e, v) => {
    setScoId(v);
    setDirection([]);
  };

  const handleDayChange = (e, v) => {
    setDayValue(v);
    setDirection([]);
    setBranchDetails(null);
    setDynamicCenterDetails(null);
  };

  const getCenterUser = async (id) => {
    const response = await fetchCenterUserList(id);
    setUserList(response);
    setUserModal(true);
  };

  const closeModal = () => {
    setUserModal(false);
  };

  const calculateRoutes = async (centerData) => {
    const origin = { lat: parseFloat(branchLatLng?.[0]), lng: parseFloat(branchLatLng?.[1]) }; // Start point
    const destination = { lat: parseFloat(branchLatLng?.[0]), lng: parseFloat(branchLatLng?.[1]) }; // End point

    const directionsService = new window.google.maps.DirectionsService();
    const waypointsChunks = chunkArray(centerData, 23);
    const promises = waypointsChunks?.map((chunk, index) => {
      const waypoints = chunk.map((coord) => ({
        location: new window.google.maps.LatLng(coord?.center_lat, coord?.center_long),
        stopover: true,
      }));

      return new Promise((resolve, reject) => {
        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode: window.google.maps.TravelMode.WALKING,
            optimizeWaypoints: true,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              let totalDist = 0;
              const route = result?.routes[0];
              route?.legs?.forEach((leg) => {
                totalDist += leg.distance.value;
              });
              // Convert to kilometers
              // setTotalDistance(totalDist / 1000);
              resolve(result);
            } else {
              reject(`error fetching directions ${result}`);
            }
          }
        );
      });
    });

    Promise.all(promises)
      .then((results) => {
        setDirection(results);
        setRouteLoading(false);
      })
      .catch((error) => {
        setRouteLoading(false);
        console.error(error);
      });
  };

  const calculateCenterRoutes = async (centerData, mainCenter) => {
    const origin = {
      lat: parseFloat(mainCenter?.center_lat),
      lng: parseFloat(mainCenter?.center_long),
    }; // Start point
    const destination = {
      lat: parseFloat(mainCenter?.center_lat),
      lng: parseFloat(mainCenter?.center_long),
    }; // End point

    const directionsService = new window.google.maps.DirectionsService();
    const waypointsChunks = chunkArray(centerData, 23);

    const promises = waypointsChunks?.map((chunk, index) => {
      const waypoints = chunk.map((coord) => ({
        location: new window.google.maps.LatLng(coord?.center_lat, coord?.center_long),
        stopover: true,
      }));

      return new Promise((resolve, reject) => {
        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
            optimizeWaypoints: true,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              let totalDist = 0;
              const route = result?.routes[0];
              route?.legs?.forEach((leg) => {
                totalDist += leg.distance.value;
              });
              // Convert to kilometers
              // setTotalDistance(totalDist / 1000);
              resolve(result);
            } else {
              reject(`error fetching directions ${result}`);
            }
          }
        );
      });
    });

    Promise.all(promises)
      .then((results) => {
        setNearestCenterDirection(results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRouteClick = async () => {
    if (direction?.length > 0) {
      setDirection([]);
    } else {
      setRouteLoading(true);
      const data = dayWiseData[dayValue];
      await calculateRoutes(data);
    }
  };

  const handleNearBy = async (center, branch, sco) => {
    setLoadingNearBy(true);
    const response = await fetchNearestCenterList(center, branch?.branch_id, sco);
    if (response?.nearest_center?.length > 0) {
      setOpenNearBy(response);
      calculateCenterRoutes(response?.nearest_center, response?.main_center);
      setLoadingNearBy(false);
    } else {
      toast.info("Nearest center is not found!");
    }
  };

  const closeNearBy = () => {
    setOpenNearBy(false);
  };

  return (
    <div>
      {loading ? (
        <Grid
          height={"100%"}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          md={12}
        >
          <img src={earth} />
        </Grid>
      ) : (
        <>
          <Grid container gap={10} justifyContent={"space-between"}>
            <Grid item md={5} sm={12}>
              <div style={{ display: "flex", gap: 15 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={branchList}
                  sx={{ width: 230, height: 50 }}
                  getOptionLabel={(option) => `${option?.branch_name} (${option?.branch_id})`}
                  renderInput={(params) => <TextField {...params} label="Branch" />}
                  onChange={handleBranchChange}
                  value={branchId}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={scoList}
                  sx={{ width: 230, height: 50 }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label="SCO List" />}
                  onChange={handleScoChange}
                  value={scoId}
                />
              </div>
            </Grid>
            <Grid item md={5} sm={12}>
              {scoDayCount ? (
                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={dayValue}
                      onChange={handleDayChange}
                      sx={{ padding: 1, color: "#fff" }}
                    >
                      <Tab value={"all"} label={"ALL"} sx={{ width: "100px" }} />
                      {Object.keys(scoDayCount).map((elem, index) => {
                        return (
                          <Tab key={index} value={elem} label={elem} sx={{ width: "100px" }} />
                        );
                      })}
                    </Tabs>
                  </Box>
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            container
            gap={2}
            style={{ height: "80vh", marginTop: 5 }}
            justifyContent={"space-around"}
          >
            <Grid
              item
              height={"100%"}
              md={5.9}
              sm={12}
              xs={12}
              position={"relative"}
              border={"2px solid lightgray"}
              width={"100%"}
            >
              {oldData?.length > 0 ? (
                <CustomMap
                  zoom={8}
                  center={{
                    lat: parseFloat(branchLatLng?.[0]),
                    lng: parseFloat(branchLatLng?.[1]),
                  }}
                  onClick={() => setOldBranchDetails(null)}
                >
                  <Marker
                    position={{
                      lat: parseFloat(branchLatLng?.[0]),
                      lng: parseFloat(branchLatLng?.[1]),
                    }}
                    icon={{
                      scaledSize: new window.google.maps.Size(55, 55),
                    }}
                    zIndex={2}
                    onClick={() => setOldBranchDetails(branchId)}
                  />
                  {oldData?.length &&
                    oldData?.map((marker, index) => (
                      <Marker
                        key={index}
                        icon={{
                          url: marker.url,
                        }}
                        position={{
                          lat: parseFloat(marker?.center_lat),
                          lng: parseFloat(marker?.center_long),
                        }}
                        onClick={() => {
                          setCenterDetails(marker);
                        }}
                        zIndex={1}
                      />
                    ))}

                  {centerDetails && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(centerDetails?.center_lat),
                        lng: parseFloat(centerDetails?.center_long),
                      }}
                      onCloseClick={() => {
                        setCenterDetails(null);
                      }}
                    >
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <h4>center id : {centerDetails?.center_code}</h4>
                        <p>center name : {centerDetails?.center_name}</p>
                        <p>center day : {centerDetails?.center_day}</p>
                        <br />
                      </div>
                    </InfoWindow>
                  )}
                  {oldBranchDetails && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(branchLatLng?.[0]),
                        lng: parseFloat(branchLatLng?.[1]),
                      }}
                      options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                      onCloseClick={() => {
                        setOldBranchDetails(null);
                      }}
                    >
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <h4>Branch id : {oldBranchDetails?.branch_id}</h4>
                        <p>Branch name : {oldBranchDetails?.branch_name}</p>
                      </div>
                    </InfoWindow>
                  )}
                </CustomMap>
              ) : (
                ""
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  zIndex: 0,
                  backgroundColor: "white",
                  padding: "2px",
                  borderRadius: "5px",
                  width: "80px",
                }}
              >
                {dayWisePin.length > 0
                  ? dayWisePin.map((item, index) => {
                      return (
                        <div key={index} style={{ display: "flex", gap: 2, marginTop: 5 }}>
                          <img style={{ height: "18px" }} src={item?.pin} alt="img" />
                          <span style={{ fontSize: 12 }}>
                            {item.day} ({oldCount[item?.day]})
                          </span>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </Grid>
            <Grid
              item
              height={"100%"}
              md={5.9}
              sm={12}
              xs={12}
              position={"relative"}
              border={"2px solid lightgray"}
              width={"100%"}
            >
              {allDynamicData?.length > 0 ? (
                <CustomMap
                  onClick={() => {
                    setBranchDetails(null);
                    setDynamicCenterDetails(null);
                  }}
                  zoom={8}
                  center={{
                    lat: parseFloat(branchLatLng?.[0]),
                    lng: parseFloat(branchLatLng?.[1]),
                  }}
                >
                  <Marker
                    position={{
                      lat: parseFloat(branchLatLng?.[0]),
                      lng: parseFloat(branchLatLng?.[1]),
                    }}
                    icon={{
                      scaledSize: new window.google.maps.Size(55, 55),
                    }}
                    zIndex={2}
                    onClick={() => setBranchDetails(branchId)}
                  />
                  {allDynamicData?.length &&
                    allDynamicData?.map((marker, index) => {
                      if (marker.new_center_day === dayValue || dayValue === "all")
                        return (
                          <Marker
                            key={index}
                            icon={{
                              url: marker.url,
                            }}
                            position={{
                              lat: parseFloat(marker?.center_lat),
                              lng: parseFloat(marker?.center_long),
                            }}
                            onClick={() => {
                              setDynamicCenterDetails(marker);
                            }}
                            zIndex={1}
                          />
                        );
                    })}

                  {dynamicCenterDetails && (
                    <InfoWindow
                      zIndex={1}
                      position={{
                        lat: parseFloat(dynamicCenterDetails?.center_lat),
                        lng: parseFloat(dynamicCenterDetails?.center_long),
                      }}
                      options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                      onCloseClick={() => {
                        setDynamicCenterDetails(null);
                      }}
                    >
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <h4>Center id : {dynamicCenterDetails?.center_code}</h4>
                        <p>Center name : {dynamicCenterDetails?.center_name}</p>
                        <p>Old center day : {dynamicCenterDetails?.center_day}</p>
                        <p>New center day : {dynamicCenterDetails?.new_center_day}</p>
                        <p>center lat : {dynamicCenterDetails?.center_lat}</p>
                        <p>center long : {dynamicCenterDetails?.center_long}</p>
                        <br />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <button
                            style={{
                              backgroundColor: "blue",
                              color: "white",
                              padding: 2,
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => getCenterUser(dynamicCenterDetails?.center_code)}
                          >
                            View User List
                          </button>
                          <button
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              padding: 5,
                              border: "none",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleNearBy(dynamicCenterDetails?.center_code, branchId, scoId)
                            }
                          >
                            {loadingNearBy ? "Loading..." : "Near By Center"}
                          </button>
                        </div>
                      </div>
                    </InfoWindow>
                  )}

                  {branchDetails && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(branchLatLng?.[0]),
                        lng: parseFloat(branchLatLng?.[1]),
                      }}
                      options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                      onCloseClick={() => {
                        setBranchDetails(null);
                      }}
                    >
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <h4>Branch id : {branchDetails?.branch_id}</h4>
                        <p>Branch name : {branchDetails?.branch_name}</p>
                      </div>
                    </InfoWindow>
                  )}
                  {direction?.map((dir, index) => (
                    <DirectionsRenderer
                      key={index}
                      directions={dir}
                      options={{
                        preserveViewport: true,
                        suppressMarkers: true,
                        polylineOptions: {
                          icons: [
                            {
                              icon: {
                                path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                scale: 3,
                                strokeColor: "#473fe8",
                                fillColor: "#db2228",
                                fillOpacity: 1,
                                strokeWeight: 2,
                                strokeOpacity: 1,
                              },
                              offset: "100%",
                              repeat: "90px",
                            },
                          ],
                        },
                      }}
                    />
                  ))}
                </CustomMap>
              ) : (
                ""
              )}
              {dayValue !== "all" && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "115px",
                    right: "10px",
                    zIndex: 0,
                  }}
                >
                  <Tooltip title={direction?.length > 0 ? "Remove route" : "View route"}>
                    <div
                      style={{
                        backgroundColor: "red",
                        padding: "10px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        cursor: "pointer",
                      }}
                      onClick={handleRouteClick}
                    >
                      {routeLoading ? (
                        <CircularProgress
                          size={"xs"}
                          sx={{ height: "20px", width: "20px" }}
                          color="inherit"
                        />
                      ) : direction?.length > 0 ? (
                        <Close />
                      ) : (
                        <Route />
                      )}
                    </div>
                  </Tooltip>
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  zIndex: 0,
                  backgroundColor: "white",
                  padding: "2px",
                  borderRadius: "5px",
                  width: "80px",
                }}
              >
                {dynamicDayWisePin.length > 0
                  ? dynamicDayWisePin.map((item, index) => {
                      return (
                        <div key={index} style={{ display: "flex", gap: 2, marginTop: 5 }}>
                          <img style={{ height: "18px" }} src={item?.pin} alt="img" />
                          <span style={{ fontSize: 12 }}>
                            {item.day} ({scoDayCount[item?.day]})
                          </span>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </Grid>
          </Grid>
        </>
      )}

      <ReactModal isOpen={userModal} onRequestClose={closeModal} style={customStyles}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Center User Details</h3>
            <Close sx={{ hight: 20, width: 20, cursor: "pointer" }} onClick={closeModal} />
          </div>
          <br />
          <div style={{ overflowY: "scroll" }}>
            <Table title="Branch List" columns={userColumns} rows={userList} />
          </div>
        </div>
      </ReactModal>

      <Modal
        open={openNearBy}
        onClose={closeNearBy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Nearest Centers</h3>
            <Close sx={{ hight: 20, width: 20, cursor: "pointer" }} onClick={closeNearBy} />
          </div>
          <div style={{ overflowY: "scroll", width: "60vw", height: "60vh" }}>
            <CustomMap
              onClick={() => {
                setCenterNearBy(null);
                setNearByCenterDetails(null);
              }}
              zoom={12}
              center={{
                lat: parseFloat(openNearBy?.main_center?.center_lat),
                lng: parseFloat(openNearBy?.main_center?.center_long),
              }}
            >
              <Marker
                position={{
                  lat: parseFloat(openNearBy?.main_center?.center_lat),
                  lng: parseFloat(openNearBy?.main_center?.center_long),
                }}
                onClick={() => {
                  setCenterNearBy(openNearBy?.main_center);
                  setNearByCenterDetails(null);
                }}
              />
              {openNearBy?.nearest_center?.length &&
                openNearBy?.nearest_center?.map((marker, index) => {
                  return (
                    <Marker
                      key={index}
                      icon={{
                        url: markerUrl[1],
                      }}
                      position={{
                        lat: parseFloat(marker?.center_lat),
                        lng: parseFloat(marker?.center_long),
                      }}
                      onClick={() => {
                        setNearByCenterDetails(marker);
                        setCenterNearBy(null);
                      }}
                      zIndex={1}
                    />
                  );
                })}

              {centerNearBy && (
                <InfoWindow
                  position={{
                    lat: parseFloat(centerNearBy?.center_lat),
                    lng: parseFloat(centerNearBy?.center_long),
                  }}
                  options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                  onCloseClick={() => {
                    setCenterNearBy(null);
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <h4>Center id : {centerNearBy?.center_id}</h4>
                    <p>Center name : {centerNearBy?.center_name}</p>
                    <p>center day : {centerNearBy?.center_day}</p>
                    <p>center lat : {centerNearBy?.center_lat}</p>
                    <p>center long : {centerNearBy?.center_long}</p>
                  </div>
                </InfoWindow>
              )}

              {nearByCenterDetails && (
                <InfoWindow
                  position={{
                    lat: parseFloat(nearByCenterDetails?.center_lat),
                    lng: parseFloat(nearByCenterDetails?.center_long),
                  }}
                  options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                  onCloseClick={() => {
                    setNearByCenterDetails(null);
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <h4>Center id : {nearByCenterDetails?.center_id}</h4>
                    <p>Center name : {nearByCenterDetails?.center_name}</p>
                    <p>center day : {nearByCenterDetails?.center_day}</p>
                    <p>center lat : {nearByCenterDetails?.center_lat}</p>
                    <p>center long : {nearByCenterDetails?.center_long}</p>
                    <p>center distance : {nearByCenterDetails?.distance}km</p>
                  </div>
                </InfoWindow>
              )}

              {nearestCenterDirection?.map((dir, index) => (
                <DirectionsRenderer
                  key={index}
                  directions={dir}
                  options={{
                    preserveViewport: true,
                    suppressMarkers: true,
                    polylineOptions: {
                      icons: [
                        {
                          icon: {
                            path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                            scale: 3,
                            strokeColor: "#473fe8",
                            fillColor: "#db2228",
                            fillOpacity: 1,
                            strokeWeight: 2,
                            strokeOpacity: 1,
                          },
                          offset: "100%",
                          repeat: "90px",
                        },
                      ],
                    },
                  }}
                />
              ))}
            </CustomMap>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SCO;
