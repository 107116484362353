import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import PropTypes from "prop-types";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const libraries = ["places"];

const CustomMap = (props) => {
  return (
    <GoogleMap
      mapTypeControl={false}
      mapContainerStyle={mapContainerStyle}
      zoom={props.zoom || 10}
      center={props.center}
      options={{ mapTypeControl: false, streetViewControl: false }}
      {...props}
    >
      {props.children}
    </GoogleMap>
  );
};

CustomMap.propTypes = {
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomMap;
